import comp0 from '@/app/scriptconsent'
import comp1 from '@/app/sentry'
import comp2 from '@/app/cookieconsent'
import comp3 from '@/app/dialog'
import comp4 from '@/app/beerslider'
import comp5 from '@/app/featureScroller'
import comp6 from '@/app/floatingScreens'
import comp7 from '@/app/galleryoverlay'
import comp8 from '@/app/characterBlender'
import comp9 from '@/app/carousel'
import comp10 from '@/app/marquee'
import comp11 from '@/app/statscounter'
import comp12 from '@/app/videoPlayer'
import comp13 from '@/app/customCarousel'
import comp14 from '@/app/stillController'
import comp15 from '@/app/formCaptcha'
import comp16 from '@/app/navToggle'
import comp17 from '@/app/scrollTop'
import comp18 from '@/app/characterBlender'
import comp19 from '@/app/videoPlayer'
import comp20 from '@/app/filterShowcases'
import comp21 from '@/app/active'
import comp22 from '@/app/collapsible'
import comp23 from '@/app/scrollTop'
import comp24 from '@/app/historticRedirect'
import comp25 from '@/app/scriptconsent'
import comp26 from '@/app/sentry'
import comp27 from '@/app/cookieconsent'
import comp28 from '@/app/dialog'
import comp29 from '@/app/beerslider'
import comp30 from '@/app/featureScroller'
import comp31 from '@/app/floatingScreens'
import comp32 from '@/app/galleryoverlay'
import comp33 from '@/app/carousel'
import comp34 from '@/app/marquee'
import comp35 from '@/app/statscounter'
import comp36 from '@/app/customCarousel'
import comp37 from '@/app/stillController'
import comp38 from '@/app/formCaptcha'
import comp39 from '@/app/navToggle'
import comp40 from '@/app/scriptconsent'
import comp41 from '@/app/sentry'
import comp42 from '@/app/cookieconsent'
import comp43 from '@/app/dialog'
import comp44 from '@/app/beerslider'
import comp45 from '@/app/featureScroller'
import comp46 from '@/app/floatingScreens'
import comp47 from '@/app/galleryoverlay'
import comp48 from '@/app/characterBlender'
import comp49 from '@/app/carousel'
import comp50 from '@/app/marquee'
import comp51 from '@/app/statscounter'
import comp52 from '@/app/videoPlayer'
import comp53 from '@/app/customCarousel'
import comp54 from '@/app/stillController'
import comp55 from '@/app/formCaptcha'
import comp56 from '@/app/navToggle'
import comp57 from '@/app/scrollTop'
var map={}; 
map['app/scriptconsent']=comp0;
map['app/sentry']=comp1;
map['app/cookieconsent']=comp2;
map['app/dialog']=comp3;
map['app/beerslider']=comp4;
map['app/featureScroller']=comp5;
map['app/floatingScreens']=comp6;
map['app/galleryoverlay']=comp7;
map['app/characterBlender']=comp8;
map['app/carousel']=comp9;
map['app/marquee']=comp10;
map['app/statscounter']=comp11;
map['app/videoPlayer']=comp12;
map['app/customCarousel']=comp13;
map['app/stillController']=comp14;
map['app/formCaptcha']=comp15;
map['app/navToggle']=comp16;
map['app/scrollTop']=comp17;
map['app/characterBlender']=comp18;
map['app/videoPlayer']=comp19;
map['app/filterShowcases']=comp20;
map['app/active']=comp21;
map['app/collapsible']=comp22;
map['app/scrollTop']=comp23;
map['app/historticRedirect']=comp24;
map['app/scriptconsent']=comp25;
map['app/sentry']=comp26;
map['app/cookieconsent']=comp27;
map['app/dialog']=comp28;
map['app/beerslider']=comp29;
map['app/featureScroller']=comp30;
map['app/floatingScreens']=comp31;
map['app/galleryoverlay']=comp32;
map['app/carousel']=comp33;
map['app/marquee']=comp34;
map['app/statscounter']=comp35;
map['app/customCarousel']=comp36;
map['app/stillController']=comp37;
map['app/formCaptcha']=comp38;
map['app/navToggle']=comp39;
map['app/scriptconsent']=comp40;
map['app/sentry']=comp41;
map['app/cookieconsent']=comp42;
map['app/dialog']=comp43;
map['app/beerslider']=comp44;
map['app/featureScroller']=comp45;
map['app/floatingScreens']=comp46;
map['app/galleryoverlay']=comp47;
map['app/characterBlender']=comp48;
map['app/carousel']=comp49;
map['app/marquee']=comp50;
map['app/statscounter']=comp51;
map['app/videoPlayer']=comp52;
map['app/customCarousel']=comp53;
map['app/stillController']=comp54;
map['app/formCaptcha']=comp55;
map['app/navToggle']=comp56;
map['app/scrollTop']=comp57;
export default map;